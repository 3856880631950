
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'DeclarationsFilters'})
export default class DeclarationsFilters extends Vue {
  @Prop()
  value!: boolean;

  @Emit('input')
  onValueChange() {
    return !this.value;
  }
}
