import TaxDeclarationRepository from '@/repositories/TaxDeclarationRepository';
import IDeclarationForm from '@/types/IDeclarationForm';
import ITaxDeclaration from '@/types/ITaxDeclaration';
import TaxDeclarationFactory from '@/factories/TaxDeclarationFactory';
import ITaxDeclarationsListParams from '@/types/ITaxDeclarationsListParams';

export default class TaxDeclarationService {
  static save(data: IDeclarationForm): Promise<void> {
    return TaxDeclarationRepository.save(data);
  }

  static async getDeclarations(params: ITaxDeclarationsListParams)
    : Promise<{ declarations: ITaxDeclaration[], totalElements: number }> {
    const {data} = await TaxDeclarationRepository.getDeclarations(params);
    return {
      declarations: data.content.map((item: any) => TaxDeclarationFactory.toTaxDeclaration(item, params.active)),
      totalElements: data.totalElements,
    };
  }

  static disable(id: number, dateTo: string): Promise<void> {
    return TaxDeclarationRepository.disable(id, dateTo);
  }

  static remove(id: number): Promise<void> {
    return TaxDeclarationRepository.remove(id);
  }
}
