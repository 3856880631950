import axios from 'axios';
import IDeclarationForm from '@/types/IDeclarationForm';
import ITaxDeclarationsListParams from '@/types/ITaxDeclarationsListParams';

export default class TaxDeclarationRepository {
  static save(data: IDeclarationForm): Promise<any> {
    return axios.post('tax', data);
  }

  static getDeclarations(params: ITaxDeclarationsListParams): Promise<any> {
    return axios.post('tax/all', params);
  }

  static disable(id: number, dateTo: string): Promise<any> {
    return axios.patch(`/tax/${id}/${dateTo}`);
  }

  static remove(id: number): Promise<any> {
    return axios.delete(`/tax/${id}`);
  }
}
