import ITaxDeclaration from '@/types/ITaxDeclaration';
import {dateToYYYYmmDD} from '@/utils/dateTime';

export default class TaxDeclarationFactory {
  static toTaxDeclaration(data: any, isActive: boolean): ITaxDeclaration {
    return {
      countryCode: data.countryCode,
      countryId: data.countryId,
      countryName: data.countryName,
      createdAt: dateToYYYYmmDD(data.createdAt),
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      declarationId: data.declarationId,
      vatin: data.vatin,
      vat21: data.vat21,
      isActive,
    };
  }
}
