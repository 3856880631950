
import {Component, Prop, Vue} from 'vue-property-decorator';
import ITaxDeclaration from '../../types/ITaxDeclaration';
import ITaxDeclarationsListParams from '@/types/ITaxDeclarationsListParams';

@Component({
  name: 'DeclarationsList',
  components: {
    RemoveDeclarationDialog: () => import('@/components/Declaration/RemoveDeclarationDialog.vue'),
    DisableDeclarationDialog: () => import('@/components/Declaration/DisableDeclarationDialog.vue'),
    DeclarationListItem: () => import('./DeclarationListItem.vue'),
  },
})
export default class DeclarationsList extends Vue {
  disableDeclarationCandidate: number | null = null;
  removeDeclarationCandidate: number | null = null;

  @Prop()
  declarations!: ITaxDeclaration[];

  @Prop()
  params!: ITaxDeclarationsListParams;

  @Prop()
  loading!: boolean;

  openDisableConfirmationDialog(id: number) {
    this.disableDeclarationCandidate = id;
  }

  openRemoveConfirmationDialog(id: number) {
    this.removeDeclarationCandidate = id;
  }

  onDeclarationDisabled() {
    this.disableDeclarationCandidate = null;
    this.$emit('fetchData');
  }

  onDeclarationRemoved() {
    this.removeDeclarationCandidate = null;
    this.$emit('fetchData');
  }
}
