export const dateToYYYYmmDD = (ISOdate: string | Date): string => {
  const date = ISOdate instanceof Date ? ISOdate : new Date(ISOdate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const startMonthDate = (date: string | Date): string => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return dateToYYYYmmDD(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1));
};

export const endMonthDate = (date: string | Date): string => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return dateToYYYYmmDD(new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0));
};

export const lastDayOfMonth = (date: string | Date): number => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0).getDate();
};

export const subtractMonths = (date: string | Date, count: number): Date => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  parsedDate.setMonth(parsedDate.getMonth() - count);
  return parsedDate;
};
