
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ITaxDeclaration from '@/types/ITaxDeclaration';

@Component({name: 'DeclarationListItem'})
export default class DeclarationListItem extends Vue {
  @Prop({required: true})
  data!: ITaxDeclaration;

  @Emit('disableDeclaration')
  disableDeclaration() {
    return this.data.declarationId;
  }

  @Emit('removeDeclaration')
  removeDeclaration() {
    return this.data.declarationId;
  }
}
