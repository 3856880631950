
import {Component, Vue} from 'vue-property-decorator';
import DeclarationListItem from '@/components/Declaration/DeclarationListItem.vue';
import DeclarationsList from '@/components/Declaration/DeclarationsList.vue';
import DeclarationsFilters from '@/components/Declaration/DeclarationsFilters.vue';
import ITaxDeclaration from '@/types/ITaxDeclaration';
import TaxDeclarationService from '@/services/TaxDeclarationService';
import {resolveError} from '@/utils/notifications';
import ITaxDeclarationsListParams from '@/types/ITaxDeclarationsListParams';

@Component({
  name: 'TaxDeclarations',
  components: {
    DeclarationForm: () => import('@/components/Declaration/DeclarationForm.vue'),
    DeclarationsFilters,
    DeclarationsList,
    DeclarationListItem,
  },
})
export default class TaxDeclarations extends Vue {
  isFormVisible: boolean = false;
  loading: boolean = false;
  declarations: ITaxDeclaration[] = [];
  params: ITaxDeclarationsListParams = {
    active: true,
    page: 1,
    size: 5,
    totalElements: 0,
  };

  created() {
    this.fetchData();
  }

  optionsUpdated(options: { page: number, itemsPerPage: number }) {
    this.params.page = options.page;
    this.params.size = options.itemsPerPage;

    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.loading = true;
      const {
        declarations,
        totalElements,
      } = await TaxDeclarationService.getDeclarations(this.params);
      this.declarations = declarations;
      this.params.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
