var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('h1',[_vm._v(_vm._s(_vm.$t('SYSTEM_TAX_RETURNS')))])])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"d-flex flex-wrap justify-space-between align-center",attrs:{"cols":"12"}},[_c('DeclarationsFilters',{on:{"input":_vm.fetchData},model:{value:(_vm.params.active),callback:function ($$v) {_vm.$set(_vm.params, "active", $$v)},expression:"params.active"}}),_c('DeclarationForm',{on:{"saved":_vm.fetchData},model:{value:(_vm.isFormVisible),callback:function ($$v) {_vm.isFormVisible=$$v},expression:"isFormVisible"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('DeclarationsList',{staticClass:"w-100",attrs:{"declarations":_vm.declarations,"loading":_vm.loading,"params":_vm.params},on:{"fetchData":_vm.fetchData}}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.declarations.length && !_vm.loading),expression:"declarations.length && !loading"}]},[_c('v-data-footer',{attrs:{"items-per-page-options":[5, 10, 30, 50],"options":{
            page: _vm.params.page,
            itemsPerPage: _vm.params.size
          },"pagination":{
            itemsPerPage: _vm.params.size,
            pageStart: (_vm.params.page - 1) * _vm.params.size,
            pageStop: _vm.params.page * _vm.params.size,
            pageCount: 0,
            itemsLength: _vm.params.totalElements
          }},on:{"update:options":_vm.optionsUpdated}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }